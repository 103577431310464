<template>
    <div class="contactInfo_body">
        <div class="contactInfo_content">
            <img :src="currentAgentSet.wechat_image" alt="">
            <p>我的微信号：{{currentAgentSet.wechat}}</p>
            <p class="contactInfo_edit" @click="editMethod">编辑</p>
        </div>
        <!-- 编辑start -->
        <setContactDialog ref='contactRef' @reload="reload" />
        <!-- 编辑end -->
    </div>
</template>
<script>
    import api from '../../http/api';
    import { mapState } from "vuex";
    export default {
        data() {
            return {
                inviteCode: '',
            }
        },
        computed: {
            ...mapState(['currentAgentSet']), //
        },
        components: {
            setContactDialog: () => import('../components/setContactDialog.vue'),
        },
        activated() {
            console.log('this.inviteCode', this.currentAgentSet)
            //this.getInfo();
        },
        methods: {
            editMethod() {
                console.log('currentAgentSet', this.currentAgentSet)
                this.$refs.contactRef.title = '编辑'
               // this.$refs.contactRef.source = 'edit'
                this.$refs.contactRef.isLock = true
               
                // this.$refs.contactRef.inputWx = this.currentAgentSet.wechat;
                // this.$refs.contactRef.uploadImg = this.currentAgentSet.wechat_image;
            },
            reload() {
                let that = this;
                api.getAgentContact().then(res => {
                    console.log('getAgentContact--->', res)
                    if (res.code == 200) {
                        if (res.response.id) {
                            that.$store.commit("currentSet", res.response);
                        }
                    }
                })
            },
            // getInfo() {
            //     api.getAgentContact().then(res => {
            //         console.log('getAgentContact--->', res)
            //     })
            // },
        }
    }
</script>
<style lang="less" scoped>
    .contactInfo_body {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .contactInfo_body img {
        width: 200px;
        height: 200px;
        background: #efefef;
    }

    .contactInfo_content p {
        text-align: center;
        width: 100%;
        margin-top: 10px;
    }

    .contactInfo_edit {
        width: 80px !important;
        line-height: 30px;
        text-align: center;
        margin: 10px auto;
        background: #0B41D7;
        color: #fff;
        cursor: pointer;
    }
</style>